<template>
	<v-sheet class="member" style="height: calc(100vh - 88px)">
		<template v-if="contentLoaded">
			<v-layout class="align-center light-blue-bg px-4 py-2">
				<v-flex md12>
					<div class="d-flex">
						<p class="my-auto font-level-3-bold">
							<ShowValue
								:object="member"
								object-key="display_name"
								label="incharge officer name"
							></ShowValue>
							<v-chip v-if="member.is_resigned" class="ma-2" color="orange" label text-color="white">
								Resigned
							</v-chip>
							<v-chip color="cyan" class="ml-2 mr-2" label text-color="white">{{ member.barcode }}</v-chip>
						</p>
						<v-spacer></v-spacer>
						<template v-if="getPermission('incharge-officer:update')">
							<v-btn
								v-if="!member.is_resigned"
								v-on:click="doAction('edit')"
								color="blue darken-4 text-white"
								class="ml-2"
								depressed
								tile
								><v-icon small left>mdi-pencil</v-icon>Edit</v-btn
							>
						</template>
						<template v-if="getPermission('incharge-officer:delete')">
							<v-btn
								v-if="!member.is_owner"
								v-on:click="doAction('delete')"
								color="blue darken-4 text-white"
								class="ml-2"
								depressed
								tile
								><v-icon small left>mdi-delete</v-icon>Delete</v-btn
							>
						</template>
						<v-btn
							v-if="!member.is_resigned && !member.is_owner"
							v-on:click="doAction('resign')"
							color="blue darken-4 text-white"
							class="ml-2"
							depressed
							tile
							><v-icon small left>mdi-file</v-icon>Resign</v-btn
						>
						<template v-if="!member.is_owner">
							<template v-if="getPermission('incharge-officer:update')">
								<v-btn
									v-if="member.activated"
									v-on:click="doAction('deactivate')"
									color="blue darken-4 text-white"
									class="ml-2"
									depressed
									tile
									><v-icon small left>mdi-cancel</v-icon>De-activate</v-btn
								>
								<v-btn
									v-else
									v-on:click="doAction('activate')"
									color="blue darken-4 text-white"
									class="ml-2"
									depressed
									tile
									><v-icon small left>mdi-check-all</v-icon>Activate</v-btn
								>
							</template>
						</template>
						<template>
							<v-btn
								v-if="memberTab == 'address-book' && !member.is_resigned"
								v-on:click="doAction('new-address')"
								color="blue darken-4 text-white"
								class="ml-2"
								depressed
								tile
								><v-icon small left>mdi-playlist-plus</v-icon>Add New Address</v-btn
							>
						</template>
					</div>
					<div class="d-flex mt-4">
						<div class="w-15 pr-3">
							<ImageTemplate circle :src="member.image"></ImageTemplate>
						</div>

						<div class="w-25">
							<div class="mt-2">
								<span class="fw-600 mr-2">Employee Id : </span>
								<span class="fw-500">
									<span class="fw-500">
										<ShowValue
											:object="member"
											object-key="id_number"
											label="identification number"
										></ShowValue>
									</span>
								</span>
							</div>
							<div class="mt-2">
								<span class="fw-600">Email : </span>
								<span class="fw-500">
									<ShowValue :object="member" object-key="email" label="email id"></ShowValue>
								</span>
							</div>
							<div class="mt-2">
								<span class="fw-600"> Phone Number : </span>
								<span class="fw-500">
									<ShowValue :object="member" object-key="phone_number" label="phone number"></ShowValue>
								</span>
							</div>
						</div>
						<div v-if="false" class="w-25">
							<div class="mt-2">
								<span class="fw-600 mr-2">Emergency Name : </span>
								<span class="fw-500">
									<span class="fw-500">
										<ShowValue
											:object="member"
											object-key="emergency_name"
											label="emergency name"
										></ShowValue>
									</span>
								</span>
							</div>
							<div class="mt-2">
								<span class="fw-600">Emergency Relation : </span>
								<span class="fw-500">
									<ShowValue
										:object="member"
										object-key="emergency_contact_relation"
										label="emergency contact relation"
									></ShowValue>
								</span>
							</div>
							<div class="mt-2">
								<span class="fw-600"> Emergency Phone Number : </span>
								<span class="fw-500">
									<ShowValue
										:object="member"
										object-key="emergency_phone_number"
										label="emergency phone number"
									></ShowValue>
								</span>
							</div>
						</div>
						<div class="w-25">
							<div class="mt-2">
								<span class="fw-600">Created On : </span>
								<span class="fw-500">
									<ShowValue :object="member" object-key="created_at" label="created on"></ShowValue>
								</span>
							</div>
							<div class="mt-2" v-if="member.is_resigned">
								<span class="fw-600">Resigned Date : </span>
								<span class="fw-500">
									<ShowValue :object="member" object-key="resigned_date" label="resigned date"></ShowValue>
								</span>
							</div>
							<div class="mt-2" v-if="member.is_resigned">
								<span class="fw-600">Resigned Reason : </span>
								<span class="fw-500">
									<ShowValue
										:object="member"
										object-key="resigned_reason"
										label="resigned reason"
									></ShowValue>
								</span>
							</div>
						</div>
					</div>
				</v-flex>
			</v-layout>
			<div>
				<v-tabs
					v-model="memberTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#project"> <v-icon small left>mdi-calendar-check</v-icon>Projects</v-tab>
					<v-tab v-if="getPermission('transaction:view')" href="#transaction">
						<v-icon small left>mdi-share-outline</v-icon> Transaction
					</v-tab>
					<v-tab href="#asset" v-if="false"> <v-icon small left>mdi-calendar-check</v-icon>Assets</v-tab>
					<v-tab href="#asset-stock" v-if="false">
						<v-icon small left>mdi-calendar-check</v-icon>Asset Stocks</v-tab
					>
					<v-tab href="#checked-out" v-if="false">
						<v-icon small left>mdi-share-outline</v-icon>Issue</v-tab
					>
					<v-tab href="#reservation"> <v-icon small left>mdi-calendar-check</v-icon>Reservations</v-tab>
					<v-tab href="#address-book" v-if="false">
						<v-icon small left>mdi-format-list-text</v-icon>Address Book</v-tab
					>
					<v-tab href="#event"> <v-icon small left>mdi-history</v-icon>Logs</v-tab>
					<v-tab href="#permission" v-if="!member.is_resigned && false">
						<v-icon small left>mdi-history</v-icon>Permissions</v-tab
					>
					<v-tab href="#password" v-if="!member.is_resigned && false">
						<v-icon small left>mdi-history</v-icon>Change Password</v-tab
					>
				</v-tabs>

				<v-tabs-items v-model="memberTab">
					<v-tab-item value="project">
						<ProjectListing
							v-if="member && member.id && memberTab == 'project'"
							class="mx-4"
							relation-type="member"
							:relation-type-id="member.id"
						></ProjectListing>
					</v-tab-item>
					<v-tab-item value="transaction">
						<template v-if="memberTab == 'transaction'">
							<TransactionListing
								relation-type="member"
								:relation-type-id="member.id"
							></TransactionListing>
						</template>
					</v-tab-item>
					<v-tab-item value="asset-stock">
						<AssetStockListing
							v-if="member && member.id && memberTab == 'asset-stock'"
							class="mx-4"
							relation-type="incharge_member"
							:no-add="addStatus"
							:relation-type-id="member.id"
						></AssetStockListing>
					</v-tab-item>
					<v-tab-item value="asset">
						<AssetListing
							v-if="member && member.id && memberTab == 'asset'"
							class="mx-4"
							:no-add="addStatus"
							relation-type="incharge_member"
							:relation-type-id="member.id"
						></AssetListing>
					</v-tab-item>
					<v-tab-item value="checked-out">
						<CheckoutListing
							v-if="member && member.id && memberTab == 'checked-out'"
							class="mx-4"
							relation-type="member"
							:relation-type-id="member.id"
							title="Issue"
							status="checked-out"
						></CheckoutListing>
					</v-tab-item>
					<v-tab-item value="reservation">
						<CheckoutListing
							v-if="member && member.id && memberTab == 'reservation'"
							class="mx-4"
							relation-type="member"
							:relation-type-id="member.id"
							title="Reservations"
							status="reserved"
						></CheckoutListing>
					</v-tab-item>
					<v-tab-item value="address-book" v-if="false">
						<MemberAddressBook
							v-if="memberTab == 'address-book'"
							type-text="Incharge Officer"
							type="member"
							:new-address="addressDialog"
							:type-uuid="uuid"
							v-on:close="addressDialog = false"
						></MemberAddressBook>
					</v-tab-item>
					<v-tab-item value="event">
						<EventListing
							v-if="memberTab == 'event'"
							class="mx-4"
							type-text="Incharge Officer"
							type="member"
							:type-uuid="uuid"
						></EventListing>
					</v-tab-item>
					<v-tab-item value="permission">
						<MemberPermission v-if="memberTab == 'permission'" :uuid="uuid"></MemberPermission>
					</v-tab-item>
					<v-tab-item value="password" v-if="!member.is_resigned">
						<div class="mx-4">
							<div class="overflow-y" style="max-height: calc(100vh - 250px)">
								<v-layout class="p-4 border-bottom-light-grey min-height-57px">
									<v-flex class="font-level-3-bold my-auto">
										<span class="detail-svg-icon mr-2">
											<!--begin::Svg Icon-->
											<inline-svg :src="$assetURL('media/custom-svg/member-color.svg')" />
											<!--end::Svg Icon-->
										</span>
										Change Password
									</v-flex>
									<v-flex class="font-level-3-bold my-auto float-right">
										<v-btn
											:loading="pageLoading"
											class="white--text mr-2 float-right"
											depressed
											color="blue darken-4"
											tile
											:disabled="pageLoading"
											v-on:click="updatePassword()"
										>
											Update Password
										</v-btn>
									</v-flex>
								</v-layout>
							</div>
						</div>
						<v-form
							ref="passwordForm"
							v-model.trim="formValid"
							lazy-validation
							v-on:submit.stop.prevent="updatePassword"
						>
							<v-row>
								<v-col md="6" class="py-0 pt-4" offset-md="3">
									<TextInput
										hide-details
										:disabled="pageLoading"
										:type="show1 ? 'text' : 'password'"
										:loading="pageLoading"
										:append-outer-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
										v-on:click:append-outer="show1 = !show1"
										id="first-name"
										placeholder="Password"
										:rules="[
											vrules.required(password, 'New Password'),
											vrules.minLength(password, 'New Password', 8),
											vrules.maxLength(password, 'New Password', 16),
										]"
										:class="{
											required: !password,
										}"
										v-model="password"
									></TextInput>
								</v-col>
								<v-col md="6" class="py-0" offset-md="3">
									<TextInput
										hide-details
										:disabled="pageLoading"
										:type="show3 ? 'text' : 'password'"
										:loading="pageLoading"
										:append-outer-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
										v-on:click:append-outer="show3 = !show3"
										id="first-name"
										placeholder="Confirm Password"
										:rules="[
											vrules.required(password_confirmation, 'Confirm Password'),
											vrules.confirmPassword(password_confirmation, 'Confirm Password', password),
											vrules.minLength(password_confirmation, 'Confirm Password', 8),
											vrules.maxLength(password_confirmation, 'Confirm Password', 16),
										]"
										:class="{
											required: !password_confirmation,
										}"
										v-model="password_confirmation"
									></TextInput>
								</v-col>
							</v-row>
						</v-form>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<DeleteTemplate
				type="Member"
				:delete-text="deleteText"
				v-on:success="goBack()"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="deleteURL"
				:delete-note="
					member.activated
						? 'To preserve associated records, you could de-activate the Incharge Officer instead.'
						: null
				"
			>
				<template v-if="member.activated" v-slot:extra-btn>
					<v-btn
						class="white--text"
						depressed
						color="blue darken-4"
						tile
						v-on:click="
							deleteDialog = false;
							deactivateDialog = true;
						"
					>
						De-activate
					</v-btn>
				</template>
			</DeleteTemplate>
			<DeactivateTemplate
				type="Member"
				:deactivate-text="deactivateText"
				v-on:success="goBack()"
				v-on:close="deactivateDialog = false"
				:deactivate-dialog="deactivateDialog"
				:deactivate-url="deactivateURL"
			>
			</DeactivateTemplate>
			<ActivateTemplate
				type="Member"
				:activate-text="activateText"
				v-on:success="goBack()"
				v-on:close="activateDialog = false"
				:activate-dialog="activateDialog"
				:activate-url="activateURL"
			>
			</ActivateTemplate>
			<ResignTemplate
				type="Member"
				:member-id="member.id"
				v-on:success="goBack()"
				v-on:close="resignDialog = false"
				:resign-dialog="resignDialog"
				:uuid="uuid"
			>
			</ResignTemplate>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
	</v-sheet>
</template>
<style>
.w-15 {
	width: 125px;
}
</style>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GetMember, UpdateMemberPassword } from "@/core/lib/member.lib";
import ImageTemplate from "@/view/components/Image";
import ShowValue from "@/view/components/ShowValue";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import ActivateTemplate from "@/view/components/ActivateTemplate";
import ResignTemplate from "@/view/components/ResignTemplate";
import DeactivateTemplate from "@/view/components/DeactivateTemplate";
import EventListing from "@/view/components/EventListing";
import CheckoutListing from "@/view/components/CheckoutListing";
import MemberAddressBook from "@/view/components/MemberAddressBook";
import MemberPermission from "@/view/module/members/MemberPermission";
import TextInput from "@/view/components/TextInput";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import AssetListing from "@/view/components/AssetListing";
import TransactionListing from "@/view/components/TransactionListing";
import AssetStockListing from "@/view/components/AssetStockListing";
import ProjectListing from "@/view/components/ProjectListing";

export default {
	name: "member-detail",
	title: "Detail Member",
	data() {
		return {
			uuid: null,
			barcode: null,
			activateText: null,
			activateURL: null,
			deactivateText: null,
			deactivateURL: null,
			deleteText: null,
			deleteURL: null,
			password_confirmation: null,
			password: null,
			formValid: true,
			deleteDialog: false,
			addressDialog: false,
			resignDialog: false,
			deactivateDialog: false,
			activateDialog: false,
			contentLoaded: false,
			pageLoading: false,
			show1: false,
			show3: false,
			memberTab: "project",
			member: {},
		};
	},
	methods: {
		doAction(param) {
			switch (param) {
				case "edit":
					this.$router.push({
						name: "member-update",
						params: { uuid: this.uuid },
						query: { t: new Date().getTime() },
					});
					break;
				case "delete":
					this.deleteDialog = true;
					break;
				case "deactivate":
					this.deactivateDialog = true;
					break;
				case "activate":
					this.activateDialog = true;
					break;
				case "new-address":
					this.addressDialog = true;
					break;
				case "resign":
					this.resignDialog = true;
					break;
				case "print-label":
					break;
			}
		},
		getMember() {
			GetMember(this.uuid)
				.then((data) => {
					this.barcode = data.barcode;
					this.member = data;
					this.deleteText = `#${data.barcode} - ${data.display_name}`;
					this.deleteURL = `member/${data.uuid}`;
					this.activateText = `${data.name}`;
					this.activateURL = `member/${data.uuid}/activate`;
					this.deactivateText = `${data.display_name}`;
					this.deactivateURL = `member/${data.uuid}/de-activate`;
					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Incharge Officer", disabled: true },
						{ text: "Detail", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.goBack();
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
		updatePassword() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.passwordForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.passwordForm.validate()) {
				return false;
			}

			UpdateMemberPassword(this.uuid, { password: this.password })
				.then(() => {
					this.password = null;
					this.password_confirmation = null;
					this.getMember();
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Password has been updated." },
					]);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
	},
	components: {
		ShowValue,
		MemberAddressBook,
		EventListing,
		ImageTemplate,
		AssetListing,
		AssetStockListing,
		ProjectListing,
		DeleteTemplate,
		ActivateTemplate,
		DeactivateTemplate,
		TextInput,
		CheckoutListing,
		ResignTemplate,
		MemberPermission,
		TransactionListing,
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Incharge Officer", disabled: true },
			{ text: "Detail", disabled: true },
		]);

		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}
		this.getMember();
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		addStatus() {
			if (this.member && this.member.is_resigned && this.member.is_resigned == 1) {
				return true;
			} else {
				return false;
			}
		},
	},
};
</script>
